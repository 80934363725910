import axios from "axios";

export const sendContactRequest = async (
  proposalData,
  requestDepartment,
  requestInfo,
  applicantName,
  applicantPhoneNumber
) => {
  // Default applicant name
  const contactRequestWebhook = process.env.REACT_APP_CONTACT_REQUEST_WEBHOOK;

  // Updating the userName to the proposal Data with Capitalizing the first letter
  proposalData["userName"]["value"] =
    applicantName.charAt(0).toUpperCase() + applicantName.slice(1);

  // Preparing payload for Slack webhook
  const payload = {
    proposalData: proposalData,
    requestDepartment: requestDepartment,
    requestInfo: requestInfo,
    applicantPhoneNumber: applicantPhoneNumber,
  };

  let response = await axios.post(contactRequestWebhook, payload);
  // console.log(response);

  return response;
};

let gadgetFunctions = {
  sendContactRequest,
};

export default gadgetFunctions;
