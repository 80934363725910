import { Icon, View } from "@aws-amplify/ui-react";
import React from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useContext } from "react";
import { PzPrimeContext } from "../Context";

const PzTooltip = ({ text, isIcon, place, children }) => {
  const { colorMode } = useContext(PzPrimeContext);

  const customCss = `
      .cursor{
          cursor:pointer;
      }
      .layerTop{
        z-index:99;
      }
      .outline{
        outline:1px solid #AEB3B7;
      }
`;

  return (
    <>
      <style>{customCss}</style>
      <View
        className="tooltip-container cursor"
        data-tooltip-id="my-tooltip"
        data-tooltip-html={text}
        data-tooltip-variant={colorMode === "dark" ? "dark" : "light"}
        data-tooltip-place={place}
        margin={'0'}
      >
        {isIcon ? (
          <Icon
            className="cursor"
            alt="Tooltip info"
            height="25px"
            color={"#fff"}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="24" fill="none" />
              <path
                d="M12 22.5C9.21523 22.5 6.54451 21.3938 4.57538 19.4246C2.60625 17.4555 1.5 14.7848 1.5 12C1.5 9.21523 2.60625 6.54451 4.57538 4.57538C6.54451 2.60625 9.21523 1.5 12 1.5C14.7848 1.5 17.4555 2.60625 19.4246 4.57538C21.3938 6.54451 22.5 9.21523 22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                fill={colorMode === "dark" ? "#fff" : "#000"}
              />
              <path
                d="M7.88313 8.679C7.88108 8.72744 7.88896 8.77579 7.90628 8.82107C7.9236 8.86636 7.95 8.90762 7.98386 8.94232C8.01772 8.97703 8.05832 9.00444 8.10317 9.02287C8.14801 9.0413 8.19615 9.05036 8.24463 9.0495H9.48213C9.68913 9.0495 9.85413 8.88 9.88113 8.6745C10.0161 7.6905 10.6911 6.9735 11.8941 6.9735C12.9231 6.9735 13.8651 7.488 13.8651 8.7255C13.8651 9.678 13.3041 10.116 12.4176 10.782C11.4081 11.5155 10.6086 12.372 10.6656 13.7625L10.6701 14.088C10.6717 14.1864 10.7119 14.2803 10.7821 14.3493C10.8522 14.4183 10.9467 14.457 11.0451 14.457H12.2616C12.3611 14.457 12.4565 14.4175 12.5268 14.3472C12.5971 14.2768 12.6366 14.1815 12.6366 14.082V13.9245C12.6366 12.8475 13.0461 12.534 14.1516 11.6955C15.0651 11.001 16.0176 10.23 16.0176 8.6115C16.0176 6.345 14.1036 5.25 12.0081 5.25C10.1076 5.25 8.02563 6.135 7.88313 8.679ZM10.2186 17.3235C10.2186 18.123 10.8561 18.714 11.7336 18.714C12.6471 18.714 13.2756 18.123 13.2756 17.3235C13.2756 16.4955 12.6456 15.9135 11.7321 15.9135C10.8561 15.9135 10.2186 16.4955 10.2186 17.3235Z"
                // fill={"#fff"}
                fill={colorMode === "dark" ? "#fff" : "#000"}
              />
              <rect
                x="-160.5"
                y="-40.5"
                width="524"
                height="817"
                rx="4.5"
                stroke="#7B61FF"
                strokeDasharray="10 5"
              />
            </svg>
          </Icon>
        ) : (
          children
        )}
      <Tooltip
        id="my-tooltip"
        style={{ maxWidth: "400px" }}
        border={"1px solid #c5c5c5"}
        
      />
      </View>
    </>
  );
};

export default PzTooltip;
