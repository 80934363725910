import {
  Flex,
  Heading,
  CheckboxField,
  TextField,
  Button,
  Icon,
  Alert,
  View,
} from "@aws-amplify/ui-react";
import { useContext, useState, useEffect } from "react";
import { PzPrimeContext } from "../Context/ProposalContext";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { DataStore } from "aws-amplify";
import { Space, Project } from "../models";

const SpaceSelectorForm = (props) => {
  const {
    changeInSpace,
    setChangeInSpace,
    colorMode,
    spaceImages,
    spaceId,
    setProjectPriceSheets,
    projectId,
    ProjectCalculator,
    projectSpaces, 
    setProjectSpaces,
    user
  } = useContext(PzPrimeContext);

  const [changeInSpaceValue, setChangeInSpaceValue] = useState(false);

  const [changedSpaceName, setChangedSpaceName] = useState(false);

  const [nameErrorFlag, setNameErrorFlag] = useState(false);

  const [isWarningVisible, setIsWarningVisible] = useState(false);

  const [isCustomNamePresent, setIsCustomNamePresent] = useState(true);

  const alertType = {
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "error",
  };

  const customCss = `
    .cursor{
        cursor:pointer;
    } 
    .pointerEvents{
      pointer-events: none; 
      user-select:none;
    }
    .outline{
      outline: 1px solid rgba(191, 191, 191, 0.80);
    }
    
    `;

  function changeInFlags(e) {
    let spaceFilterInputs = props.tempSpaceInputs;
    let spaceField = spaceFilterInputs["costFlags"].filter(
      (inputField) => inputField.featureTitle === e.target.id
    );
    let spaceFieldInput = spaceField[0];
    spaceFieldInput["checkedValue"] = e.target.checked;
    props.setTempSpaceInputs(props.tempSpaceInputs);
    setChangeInSpaceValue(!changeInSpaceValue);
  }

  function changeInAreaValue(e) {
    if (e.target.value < 0 || e.target.value === "") e.target.value = 0;
    let spaceFilterInputs = props.tempSpaceInputs;
    let spaceField = spaceFilterInputs["areas"].filter(
      (inputField) => inputField.areaTitle === e.target.id
    );
    let spaceFieldInput = spaceField[0];
    spaceFieldInput["area"] = parseInt(e.target.value);
    props.setTempSpaceInputs(props.tempSpaceInputs);
    setChangeInSpaceValue(!changeInSpaceValue);
  }

  function changeInValue(e) {
    if (e.target.name === "INTEGER")
      if (e.target.value < 0 || e.target.value === "") e.target.value = 0;
    let spaceFilterInputs = props.tempSpaceInputs;
    let spaceField = spaceFilterInputs["auxInputs"].filter(
      (inputField) => inputField.inputTitle === e.target.id
    );
    let spaceFieldInput = spaceField[0];
    if (e.target.name === "INTEGER")
      spaceFieldInput["intValue"] = parseInt(e.target.value);
    else spaceFieldInput["checkedValue"] = e.target.checked;
    props.setTempSpaceInputs(props.tempSpaceInputs);
    setChangeInSpaceValue(!changeInSpaceValue);
  }

  useEffect(() => {}, [changeInSpaceValue]);

  function changeInSpaceName(e) {
    if (e.target.value === "") {
      setIsCustomNamePresent(false);
    } else {
      setIsCustomNamePresent(true);
      DataStore.observeQuery(Space, (space) =>
        space.customName.eq(e.target.value)
      ).subscribe(({ items }) => {
        if (items.length !== 0) setNameErrorFlag(true);
        else setNameErrorFlag(false);
      });
    }
    setChangedSpaceName(true)
    props.tempSpaceInputs.customName = e.target.value;
    props.setTempSpaceInputs(props.tempSpaceInputs);
    setChangeInSpaceValue(!changeInSpaceValue);

    setIsWarningVisible(false);
  }

  async function nextStep() {
    if (!nameErrorFlag && isCustomNamePresent) {
      // if(props.mode === 'edit') {
      //   const currentSpace = await DataStore.query(Space, spaceId);
      //   await DataStore.save(
      //     Space.copyOf(currentSpace, (spaceCopy) => {
      //       spaceCopy.className = props.tempSpaceInputs.className;
      //       spaceCopy.areas = props.tempSpaceInputs.areas;
      //       spaceCopy.auxInputs = props.tempSpaceInputs.auxInputs;
      //       spaceCopy.costFlags = props.tempSpaceInputs.costFlags;
      //       spaceCopy.customName = props.tempSpaceInputs.customName;
      //     })
      //   ).then((res)=>{setTimeout(()=>{ProjectCalculator(projectId, setProjectPriceSheets);},2000)}).catch((err)=>console.log(err))

      // }
      // else {
      //   await DataStore.save(
      //     new Space({
      //       customName: props.tempSpaceInputs.customName,
      //       className: props.tempSpaceInputs.className,
      //       templateID: props.tempSpaceInputs.templateID,
      //       areas: props.tempSpaceInputs.areas,
      //       auxInputs: props.tempSpaceInputs.auxInputs,
      //       costFlags: props.tempSpaceInputs.costFlags,
      //       projectSpacesId: props.tempSpaceInputs.projectSpacesId,
      //       priceSheet: props.tempSpaceInputs.priceSheet
      //   })).then((res)=>{setTimeout(()=>{ProjectCalculator(projectId, setProjectPriceSheets);},2000)}).catch((err)=>console.log(err))
      // }
      let tempSpaceDict = {}
      if(projectSpaces)
        tempSpaceDict = JSON.parse(JSON.stringify(projectSpaces))
      console.log(changedSpaceName)
      if(changedSpaceName) {
        delete tempSpaceDict[props.selectedSpace]
      }
      tempSpaceDict[props.tempSpaceInputs.customName] = props.tempSpaceInputs
      setProjectSpaces(tempSpaceDict)
      const currentProject = await DataStore.query(Project, projectId)
      await DataStore.save(
        Project.copyOf(currentProject, (projectCopy)=>{
          projectCopy.spaceDict = tempSpaceDict
        })
      ).then((res)=>{
        setTimeout(()=>{ProjectCalculator(projectId, setProjectPriceSheets, setProjectSpaces, user);},2000)
      })
      props.setSelectedSpace("");
      props.setAddNewSpace(false);
    } else {
      setIsWarningVisible(true);
    }
  }

  return (
    <>
      <Flex direction={"column"} gap={"0px"}>
        <style>{customCss}</style>
        <Flex
          className="outline"
          padding="28px 70px 28px 34px"
          alignItems="center"
          boxShadow="0px 1px 4px 0px rgba(0, 0, 0, 0.25)"
        >
          <Button
            variation="link"
            textAlign={"center"}
            padding={"10px 30px 10px 10px"}
            margin={"0px 15px"}
            onClick={() => {
              if (props.mode === "edit")
                props.setAddNewSpace(false);
              else props.setAddNewSpace(true);
              props.setSelectedSpace("");
            }}
          >
            <Icon
              ariaLabel="Close"
              fontSize={"30px"}
              pathData="M33.3332 13.75H13.0498L22.3665 6.7625L19.9998 5L6.6665 15L19.9998 25L22.3498 23.2375L13.0498 16.25H33.3332V13.75Z"
            />
          </Button>
          <Heading level={3}>{props.tempSpaceInputs.className}</Heading>
        </Flex>
        <Flex
          justifyContent={"flex-start"}
          alignItems={"stretch"}
          padding={"30px"}
        >
          <Flex
            direction={"column"}
            maxWidth={"800px"}
            width={"100%"}
            padding={"30px"}
            margin={"0 10px"}
            borderRadius={"20px"}
            backgroundColor={colorMode === "dark" && "#2e3436"}
          >
            <TextField
              fontSize={"20px"}
              padding={"5px"}
              label="Custom Name"
              id="name"
              value={props.tempSpaceInputs.customName}
              errorMessage={
                nameErrorFlag ? "This name is already used" : "Enter this field"
              }
              hasError={nameErrorFlag || !isCustomNamePresent}
              onChange={(e) => changeInSpaceName(e)}
            />
            {props.tempSpaceInputs["areas"].map((spaceField, index) => (
              <TextField
                key={index}
                fontSize={"20px"}
                padding={"5px"}
                onFocus={(e) => e.target.select()}
                type="number"
                onWheel={(e) => e.target.blur()}
                label={spaceField.areaTitle + " (SF)"}
                id={spaceField.areaTitle}
                value={spaceField.area ? spaceField.area : ""}
                onChange={(e) => changeInAreaValue(e)}
                placeholder="0 SF"
              />
            ))}
            {props.tempSpaceInputs["auxInputs"].map((spaceField, index) => {
              if (spaceField.dataType === "CHECKBOX") {
                return (
                  <CheckboxField
                    key={index}
                    label={spaceField.inputTitle}
                    fontSize={"20px"}
                    padding={"10px"}
                    id={spaceField.inputTitle}
                    name={spaceField.dataType}
                    checked={spaceField.checkedValue}
                    onChange={(e) => changeInValue(e)}
                  />
                );
              } else {
                return (
                  <TextField
                    key={index}
                    fontSize={"20px"}
                    padding={"5px"}
                    onFocus={(e) => e.target.select()}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    label={spaceField.inputTitle}
                    id={spaceField.inputTitle}
                    name={spaceField.dataType}
                    value={spaceField.intValue ? spaceField.intValue : ""}
                    onChange={(e) => changeInValue(e)}
                    placeholder="0"
                  />
                );
              }
            })}
          </Flex>
          <View
            border={"1px solid #c5c5c5"}
            borderRadius={"20px"}
            overflow={"hidden"}
            className="pointerEvents"
            maxHeight={"500px"}
            width={"100%"}
            maxWidth={"900px"}
          >
            <LazyLoadImage
              src={
                spaceImages[props.tempSpaceInputs.templateID]
                  ? spaceImages[props.tempSpaceInputs.templateID]?.imageURL
                  : require("../Assets/defaultSpaceImage.jpg")
              }
              alt={props.tempSpaceInputs.className}
              height="100%"
              width="100%"
              className="pointerEvents"
              effect="blur"
              borderRadius={"20px"}
              style={{
                objectFit: "cover",
              }}
            />
          </View>
        </Flex>
        <Flex padding="28px 70px 28px 44px" direction={"column"}>
          {props.tempSpaceInputs["costFlags"].length > 0 && (
            <Heading level={3}>Space Features - Check All that Apply</Heading>
          )}
          {props.tempSpaceInputs["costFlags"].map((flag, index) => (
            <Flex key={index} direction={"column"} padding={"10px 20px"}>
              <CheckboxField
                className="checkBox"
                label={flag.featureTitle}
                id={flag.featureTitle}
                value="yes"
                labelPosition="end"
                checked={flag.checkedValue}
                onChange={(e) => changeInFlags(e)}
              />
              <Alert
                variation={alertType[flag.alertLevel]}
                isDismissible={false}
                hasIcon={true}
                heading={flag.featureTitle}
                maxWidth={"1200px"}
                width={"100%"}
                marginLeft={"25px"}
                display={flag.checkedValue ? "flex" : "none"}
              >
                {flag.alertBody}
              </Alert>
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Flex direction={"column"}>
        {isWarningVisible && (
          <Alert
            variation="warning"
            position={"fixed"}
            bottom={"100px"}
            right={"360px"}
            heading={
              !isCustomNamePresent
                ? "Please fill all highlighted fields to continue"
                : "The Custom name should be unique"
            }
          />
        )}
        <Button
          position={"fixed"}
          bottom={"25px"}
          right={"400px"}
          width={"200px"}
          backgroundColor={"#40AABF"}
          color={"#fff"}
          margin={"10px 30px"}
          onClick={() => {
            nextStep();
          }}
        >
          Next Step
        </Button>
      </Flex>
    </>
  );
};

export default SpaceSelectorForm;
