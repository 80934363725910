// Amplify
import { Flex, View } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import {
  Project,
  ProjectLocation,
  PriceSheet,
  PricingModel,
  BasePrice,
  PriceClass,
} from "../../models";

// Gadget Components
import {
  MarketSelector,
  ProjectDetails,
  SpaceSelector,
  SpaceTemplateFormView,
} from "..";
import {
  ProgressBar,
  ProjectSummary,
  Header,
  HomePage,
} from "../../GadgetComponents";

import { Outlet, Route, Routes, useLocation } from "react-router-dom";

import { useContext, useEffect } from "react";
import { PzPrimeContext } from "../../Context";
import AdjustPricingForm from "../AdjustPricingForm";
import CostFlagTemplateForm from "../CostFlagTemplateForm";
import DynamicQuoteForm from "../InstantQuoteForm/DynamicQuoteForm";
import QuoteFormEmbed from "../InstantQuoteForm/QuoteFormEmbed";
import PromoCodeForm from "../PromoCodeFormView";

const MainBodyGridItem = (props) => {
  const { ...rest } = props;

  const {
    currentStage,
    changeInInput,
    projectId,
    setProjectId,
    projectName,
    projectArea,
    numberOfBuildings,
    buildingArea,
    selectedMarkets,
    constructionType,
    discipline,
    location,
    distanceMatrixData,
    address,
    selectedPackage,
    userDetails,
    user,
  } = useContext(PzPrimeContext);

  const pathLocation = useLocation();

  const { pathname } = pathLocation;

  async function saveProject() {
    let projectBaseFees = {};
    let baseFeesRecords = await DataStore.query(BasePrice);
    if (baseFeesRecords.length > 0) {
      baseFeesRecords.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
      let trades = ["mech", "elec", "plum"];
      for (let trade of trades) {
        projectBaseFees[trade] = [
          baseFeesRecords[0]["disciplines"][trade]["setUpFee"],
          baseFeesRecords[0]["disciplines"][trade]["costPerSF"],
        ];
      }
    }

    const constructionTypeConversion = {
      "New Construction": "GROUND_UP",
      "Adaptive Reuse/Clean Slate": "ADAPTIVE_REUSE_CLEAN_SLATE",
      "Adaptive Reuse/Recycled System": "ADAPTIVE_REUSE_RECYCLED_SYSTEMS",
    };

    const marketConversion = {
      "Multifamily & Mixed Use": "MULTIFAMILY_MIXED_USE",
      "Office and Retail": "OFFICE_RETAIL",
      "Food and Beverage": "FOOD_BEVERAGE",
      Healthcare: "HEALTHCARE",
      "Core / Shell": "CORE_SHELL",
      "Warehouse / Storage": "WAREHOUSE_STORAGE",
      "Single Family Residential": "SINGLE_FAMILY_RESIDENTIAL",
      "Religious and Cultural": "RELIGIOUS_CULTURAL",
      "Education and Daycare": "EDUCATION_DAYCARE",
      "Factory | Industrial | Garage": "FACTORY_INDUSTRIAL_GARAGE",
      "Theaters & Event Spaces": "THEATERS_EVENT_SPACES",
      Other: "OTHER",
    };

    const tradeConversion = {
      mechanical: "MECH",
      electrical: "ELEC",
      plumbing: "PLUMB",
      gas: "GAS",
    };

    const packageLevelConversion = {
      Minimalist: "MINIMALIST",
      Collaborator: "COLLABORATOR",
      Perfectionist: "PERFECTIONIST",
    };

    let trades = [];
    for (const trade in discipline) {
      if (discipline[trade] === 1) trades.push(tradeConversion[trade]);
    }

    let markets = [];
    for (const market of selectedMarkets) {
      markets.push(marketConversion[market]);
    }

    if (projectId) {
      const currentProject = await DataStore.query(Project, projectId);
      // let editors;
      // if (user && !currentProject.editors) editors = [user];

      await DataStore.save(
        Project.copyOf(currentProject, (projectCopy) => {
          projectCopy.name = projectName;
          projectCopy.projectArea = projectArea;
          projectCopy.numberOfBuildings = numberOfBuildings;
          projectCopy.totalBuildingsArea = buildingArea;
          projectCopy.userEmail = userDetails.emailId
            ? userDetails.emailId
            : null;
          projectCopy.markets = markets;
          projectCopy.constructionType =
            constructionTypeConversion[constructionType];
          projectCopy.tradeSupervisions = trades;
          projectCopy.location = new ProjectLocation({
            latitude: location.lat,
            longitude: location.lng,
            address: address,
            driveDistance: distanceMatrixData.distance,
            driveDuration: distanceMatrixData.time,
          });
          projectCopy.packageLevel = packageLevelConversion[selectedPackage];
          // projectCopy.editors = editors;
        })
      )
        .then((res) => {})
        .catch((err) => console.log(err));
    } else {
      if (projectName)
        await DataStore.save(
          new Project({
            name: projectName,
            projectArea: projectArea,
            numberOfBuildings: numberOfBuildings,
            totalBuildingsArea: buildingArea,
            markets: selectedMarkets,
            userEmail: userDetails.emailId ? userDetails.emailId : null,
            constructionType: constructionTypeConversion[constructionType],
            tradeSupervisions: trades,
            location: new ProjectLocation({
              latitude: location.lat,
              longitude: location.lng,
              address: address,
              driveDistance: distanceMatrixData.distance,
              driveDuration: distanceMatrixData.time,
            }),
            packageLevel: packageLevelConversion[selectedPackage],
            editors: user ? [user] : [],
            priceSheet: new PriceSheet({
              pricingModel: PricingModel.PROJECT_BASE_FEES,
              mech: projectBaseFees["mech"],
              elec: projectBaseFees["elec"],
              plum: projectBaseFees["plum"],
            }),
            promoCodes: [],
          })
        )
          .then((res) => {
            setProjectId(res.id);
          })
          .catch((err) => console.log(err));
    }
  }

  useEffect(() => {
    saveProject();
  }, [changeInInput]);

  const Css = `
  .homeAndAdminPageLayer{
    position: relative;
    z-index: 2;
  }
    .mainScrollBar::-webkit-scrollbar {
      width: 15px;               /* width of the scrollbar */
    background-color: transparent;
  }
  .mainScrollBar::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  .mainScrollBar::-webkit-scrollbar-thumb {
    background-color: #b0b3b5;    /* color of the scroll thumb */
    background-clip: content-box;
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 2px solid transparent;  /* creates padding around scroll thumb */
  }
  .mainScrollBar{
    z-index: 1;
  }
  .headerLayer{
    z-index:3;
  }
  `;

  return (
    <Flex width={"100%"} direction={"column"} gap={"0"}>
      <style>{Css}</style>
      {pathname !== "/embed-quote" && (
        <Header position="sticky" top="0px" className="headerLayer" />
      )}

      <Flex
        width={"100%"}
        className="homeAndAdminPageLayer"
        direction={"column"}
      >
        <Routes>
          <Route path="/" element={<HomePage />} />

          {/* Dynamic Quote Form */}
          <Route path="/quote" element={<DynamicQuoteForm />} />

          {/* Wix View Form */}
          <Route path="/embed-quote" element={<QuoteFormEmbed />} />

          {/* Admin Options */}
          <Route path="/pricing" element={<AdjustPricingForm />} />
          <Route path="/space-templates" element={<SpaceTemplateFormView />} />
          <Route path="/promo-codes" element={<PromoCodeForm />} />
          <Route path="/cost-flags" element={<CostFlagTemplateForm />} />
        </Routes>
        {/* <View className="invisibleBlockIq"></View> */}
      </Flex>

      <Flex width={"100%"} gap={"0"} top={"121px"} position={"fixed"}>
        <Flex
          direction={"column"}
          width={"100%"}
          gap={"0"}
          position={"relative"}
        >
          {currentStage !== "homePage" && currentStage !== "instantQuote" && (
            <ProgressBar
              position="sticky"
              left="0px"
              top={"0px"}
              width="100%"
            />
          )}
          <Flex
            height={"76vh"}
            border="50px"
            overflow={"auto"}
            width={"100%"}
            className="mainScrollBar"
            direction={"column"}
          >
            <Routes>
              {/* Detailed Estimate routes */}
              <Route path="/project-details" element={<ProjectDetails />} />
              <Route path="/market-selector" element={<MarketSelector />} />
              <Route path="/space-selector" element={<SpaceSelector />} />
            </Routes>
            <View className="invisibleBlock"></View>
          </Flex>
        </Flex>

        {currentStage !== "projectDetails" &&
          currentStage !== "homePage" &&
          currentStage !== "instantQuote" && (
            <ProjectSummary
              minWidth="400px"
              maxWidth="450px"
              top="121px"
              right="0px"
            />
          )}
      </Flex>

      <Outlet />
    </Flex>
  );
};

export default MainBodyGridItem;
