import {
  CheckboxField,
  Flex,
  Heading,
  Text,
  View,
  Icon,
  Button,
} from "@aws-amplify/ui-react";
import { useState, useContext, useEffect } from "react";
import SpaceSelectorForm from "./SpaceSelectorForm";
import { PzPrimeContext } from "../Context/ProposalContext";
import { SpaceSelectorOverview } from ".";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { DataStore, Storage } from "aws-amplify";
import {
  SpaceTemplate,
  Space,
  CostFlagTemplate,
  SpaceCostFlagRelationships,
} from "../models";
import { useNavigate } from "react-router-dom";

const SpaceSelector = (props) => {
  const navig = useNavigate();

  const {
    colorMode,
    spaceImages,
    changeInSpace,
    projectId,
    setSpaceId,
    filterMarkets,
    requirementChecker,
    testPricing,
    setFilterMarkets,
    setCurrentStage,
    setActiveStage,
    stageValue,
    currentStage,
    setSpaceImages,
    projectSpaces, 
    setProjectSpaces,
  } = useContext(PzPrimeContext);

  const [selectedSpace, setSelectedSpace] = useState("");

  const [mode, setMode] = useState("");

  const [addNewSpace, setAddNewSpace] = useState(false);

  const [tempSpaceInputs, setTempSpaceInputs] = useState({});

  const [spaceTemplates, setSpaceTemplates] = useState([]);

  const [spaces, setSpaces] = useState([]);

  const [costFlags, setCostFlags] = useState([]);

  const markets = {
    "Multifamily & Mixed Use": "MULTIFAMILY_MIXED_USE",
    "Office and Retail": "OFFICE_RETAIL",
    "Food and Beverage": "FOOD_BEVERAGE",
    Healthcare: "HEALTHCARE",
    "Core / Shell": "CORE_SHELL",
    "Warehouse / Storage": "WAREHOUSE_STORAGE",
    "Single Family Residential": "SINGLE_FAMILY_RESIDENTIAL",
    "Religious and Cultural": "RELIGIOUS_CULTURAL",
    "Education and Daycare": "EDUCATION_DAYCARE",
    "Factory | Industrial | Garage": "FACTORY_INDUSTRIAL_GARAGE",
    "Theaters & Event Spaces": "THEATERS_EVENT_SPACES",
    Other: "OTHER",
  };

  async function getImages(items) {
    let totalSpacesImage = {};
    for (const space of items) {
      if (space.imageKey)
        totalSpacesImage[space.id] = {
          imageURL: await Storage.get(`assets/spaceImages/${space.imageKey}`, {
            download: true,
          }).then((imageURL) => URL.createObjectURL(imageURL.Body)),
        };
    }
    setSpaceImages(totalSpacesImage);
  }

  async function getSpaces() {
    DataStore.observeQuery(Space, (space) =>
      space.projectSpacesId.eq(projectId)
    ).subscribe(({ items }) => {
      setSpaces(items);
    });
  }

  async function getTemplates() {
    DataStore.observeQuery(SpaceTemplate).subscribe(({ items }) => {
      setSpaceTemplates(items);
      getImages(items);
    });
    DataStore.observeQuery(CostFlagTemplate).subscribe(({ items }) => {
      setCostFlags(items);
    });
  }

  useEffect(() => {
    // if(currentStage === 'spaceSelector')
    getTemplates();
  }, []);

  useEffect(() => {
    // if(currentStage === 'spaceSelector')
    getSpaces();
  }, [changeInSpace]);

  const customCss = `
  .cursor{
      cursor:pointer;
  } 
  .pointerEvents{
    pointer-events: none; 
    user-select:none;
  }
  .hover:hover{
    -webkit-box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.75);
    transition: 0.2s ease-in;
  }
  .outline{
    outline: 1px solid rgba(191, 191, 191, 0.80);
  }
  `;

  if (requirementChecker("/space-selector")[0])
    navig(requirementChecker("/space-selector")[1]);

  setCurrentStage("spaceSelector");
  setActiveStage(stageValue[currentStage] - 1);

  async function checkSelectedSpace(space) {
    let name;
    if (projectSpaces && Object.keys(projectSpaces).length > 0) {
      let spaceFilter = Object.keys(projectSpaces).map((spaceInput) => {
        if (projectSpaces[spaceInput]['templateID'] === space.id) return projectSpaces[spaceInput]['customName'];
      });
      if (spaceFilter.length > 0) {
        let i = 1;
        let nameFlag = false;
        while (!nameFlag) {
          let tempName;
          if (i === 1) tempName = space.templateName;
          else tempName = space.templateName + "-" + i;
          if (!spaceFilter.includes(tempName)) {
            name = tempName;
            nameFlag = true;
          }
          i++;
        }
      } else name = space.templateName;
    } else {
      name = space.templateName;
    }
    let newSpace = {
      customName: name,
      className: space.templateName,
      templateID: space.id,
      areas: [],
      auxInputs: [],
      costFlags: [],
      priceSheet: space.priceSheet,
    };
    for (const input of space.areas) {
      let spaceInputField = {
        areaTitle: input.areaTitle,
        area: 0,
        priceSheet: input.priceSheet,
      };
      newSpace["areas"].push(spaceInputField);
    }
    for (const input of space.auxInputs) {
      let spaceAuxInputField = {
        inputTitle: input.inputTitle,
        dataType: input.dataType,
      };
      if (input.dataType === "INTEGER") spaceAuxInputField["intValue"] = 0;
      else spaceAuxInputField["checkedValue"] = false;
      newSpace["auxInputs"].push(spaceAuxInputField);
    }
    const spaceFlags = await DataStore.query(
      SpaceCostFlagRelationships,
      (relation) => relation.spaceTemplateId.eq(space.id)
    );
    for (const input of spaceFlags) {
      const flag = await DataStore.query(
        CostFlagTemplate,
        input.costFlagTemplateId
      );
      let spaceFlags = {
        featureTitle: flag.featureTitle,
        checkedValue: false,
        alertLevel: flag.alertLevel,
        alertTitle: flag.alertTitle,
        alertBody: flag.alertBody,
      };
      newSpace["costFlags"].push(spaceFlags);
    }
    setTempSpaceInputs(newSpace);
    setMode("create");
    setSelectedSpace(newSpace.customName);
  }

  function SpaceCard(space) {
    return (
      <Flex
        backgroundColor={colorMode === "dark" && "#2e3436"}
        direction={"column"}
        alignItems={"center"}
        padding={"15px"}
        borderRadius={"10px"}
        width={'320px'}
        border={"1px solid #c5c5c5"}
        className={"cursor hover"}
        gap={"15px"}
        onClick={() => checkSelectedSpace(space.space)}
      >
        <View
          border={"1px solid #c5c5c5"}
          borderRadius={"10px"}
          overflow={"hidden"}
          className="pointerEvents"
          height={"205px"}
          width={"100%"}
        >
          <LazyLoadImage
            src={
              spaceImages[space.space.id]
                ? spaceImages[space.space.id]?.imageURL
                : require("../Assets/defaultSpaceImage.jpg")
            }
            alt={space.space.templateName}
            height="100%"
            width="100%"
            className="pointerEvents"
            effect="blur"
            style={{
              objectFit: "cover",
              border: "1px solid #c5c5c5",
              borderRadius: "10px",
            }}
          />
        </View>
        <Text
          className={"cursor pointerEvents"}
          margin={"8px"}
          marginBottom={'15px'}
          textAlign={"center"}
          fontSize="25px"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="130%"
        >
          {space.space.templateName}
        </Text>
      </Flex>
    );
  }

  function changeFilter(e) {
    let sample = filterMarkets;
    if (!e.target.checked)
      sample = sample.filter((market) => market !== e.target.value);
    else sample = [...sample, e.target.value];
    setFilterMarkets(sample);
  }

  return (
    <Flex direction={"column"} width={"100%"}>
      <style>{customCss}</style>
      {!selectedSpace ? (
         projectSpaces && Object.keys(projectSpaces).length > 0 && !addNewSpace ? (
          <SpaceSelectorOverview
            selectedSpace={selectedSpace}
            setSelectedSpace={setSelectedSpace}
            addNewSpace={addNewSpace}
            setAddNewSpace={setAddNewSpace}
            mode={mode}
            setMode={setMode}
            tempSpaceInputs={tempSpaceInputs}
            setTempSpaceInputs={setTempSpaceInputs}
            spaces={spaces}
          />
        ) : (
          <>
            <Flex
              className="outline"
              padding="28px 70px 28px 34px"
              alignItems="center"
              gap="10px"
              boxShadow="0px 1px 4px 0px rgba(0, 0, 0, 0.25)"
            >
              {addNewSpace && projectSpaces && Object.keys(projectSpaces).length > 0 && (
                <Button
                  variation="link"
                  textAlign={"center"}
                  padding={"10px 30px 10px 10px"}
                  margin={"0px 15px"}
                  onClick={() => setAddNewSpace(false)}
                >
                  <Icon
                    ariaLabel="Close"
                    fontSize={"30px"}
                    pathData="M33.3332 13.75H13.0498L22.3665 6.7625L19.9998 5L6.6665 15L19.9998 25L22.3498 23.2375L13.0498 16.25H33.3332V13.75Z"
                  />
                </Button>
              )}
              <Heading level={3}>
                { projectSpaces && Object.keys(projectSpaces).length > 0
                  ? "Add Another Space to your Project:"
                  : "Add a Space to your Project:"}
              </Heading>
            </Flex>

            <Flex width={"100%"}>
              <View width={"100%"} maxWidth={"300px"} margin={"10px 20px"}>
                <Heading>Filter By Markets</Heading>
                <Flex gap={"10px"} padding={"15px"} direction={"column"}>
                  {Object.keys(markets).map((market, index) => (
                    <CheckboxField
                      key={index}
                      label={market}
                      value={market}
                      onClick={(e) => changeFilter(e)}
                      checked={filterMarkets.includes(market)}
                    />
                  ))}
                </Flex>
              </View>
              <Flex
                width={"100%"}
                wrap={"wrap"}
                margin={"10px 20px"}
                justifyContent={"flex-start"}
                direction={"column"}
              >
                <Flex direction={"column"} width={"100%"}>
                  <Heading level={3}>Spaces in Selected Markets:</Heading>

                  <Flex wrap={"wrap"}>
                    {spaceTemplates.map((item, index) => {
                      if (filterMarkets.length === 0)
                        return <SpaceCard key={index} space={item} />;
                      else {
                        let isPresent = false;
                        for (const market of filterMarkets) {
                          if (item.markets.includes(markets[market])) {
                            isPresent = true;
                            break;
                          }
                        }
                        if (isPresent)
                          return <SpaceCard key={index} space={item} />;
                      }
                    })}
                  </Flex>
                </Flex>
                <Flex direction={"column"} width={"100%"}>
                  <Heading level={3}>Spaces in All Markets:</Heading>
                  <Flex wrap={"wrap"}>
                    {spaceTemplates.map((item, index) => {
                      return <SpaceCard key={index} space={item} />;
                    })}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </>
        )
      ) : (
        <>
          <SpaceSelectorForm
            selectedSpace={selectedSpace}
            setSelectedSpace={setSelectedSpace}
            tempSpaceInputs={tempSpaceInputs}
            setTempSpaceInputs={setTempSpaceInputs}
            mode={mode}
            setAddNewSpace={setAddNewSpace}
            spaces={spaces}
          />
        </>
      )}
    </Flex>
  );
};

export default SpaceSelector;
