import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Flex,
  Heading,
  Icon,
  SelectField,
  Text,
  TextAreaField,
  TextField,
  View,
} from "@aws-amplify/ui-react";
import { ModalComponent } from "../index";
import { PzPrimeContext } from "../../Context";

const ContactInfoModal = ({ props, modalPosition }) => {
  const { projectName, isContactVisible, setIsContactVisible } = props;

  const {
    colorMode,
    lastSentProposal,
    userDetails,
    swalWithDefaults,
    gadgetFunctions,
  } = useContext(PzPrimeContext); // from useContext

  //useStates
  const [requestDepartment, setRequestDepartment] = useState("General");
  const [requestInfo, setRequestInfo] = useState("");
  const [applicantPhoneNumber, setApplicantPhoneNumber] = useState("");
  const [applicantName, setApplicantName] = useState("");

  useEffect(() => {
    // console.log("User details updated:", userDetails);
    if (userDetails) {
      setApplicantName(userDetails.name);
      setApplicantPhoneNumber(userDetails.phoneNumber);
    }
  }, [userDetails, isContactVisible]);

  // console.log(userDetails);
  // console.log(applicantName, applicantPhoneNumber);

  // Email requirements
  const recipient = "team@permitzip.com";
  const subject = `New InstantQuote Inquiry: ${projectName}`;
  // const body = "This is the body of the email.";
  const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
    subject
  )}}`;

  function changeInPhoneNumber(e) {
    let phoneNumbers = e.target.value.split("-").join("");
    let tempPhoneNumber = "";
    if (/^[0-9]*$/.test(phoneNumbers) && phoneNumbers.length < 11) {
      if (phoneNumbers.length > 6)
        tempPhoneNumber =
          phoneNumbers.slice(0, 3) +
          "-" +
          phoneNumbers.slice(3, 6) +
          "-" +
          phoneNumbers.slice(6);
      else if (phoneNumbers.length > 3)
        tempPhoneNumber =
          phoneNumbers.slice(0, 3) + "-" + phoneNumbers.slice(3);
      else tempPhoneNumber = phoneNumbers;
      setApplicantPhoneNumber(tempPhoneNumber);
    } else alert("Enter a valid phone number");
  }

  const handleButtonClick = async () => {
    if (applicantPhoneNumber.length === 12 && applicantName.length > 0) {
      try {
        await gadgetFunctions.sendContactRequest(
          lastSentProposal,
          requestDepartment,
          requestInfo,
          applicantName,
          applicantPhoneNumber
        )
          .then((res) => {
            console.log(res);
            swalWithDefaults.fire({
              icon: "success",
              title: "Request sent!",
              text: "Our team will be in contact with you soon! If you'd like to get in touch ASAP, please dial 833-896-9335.",
            });
          })
          .catch((err) => {
            console.log(err);
            swalWithDefaults.fire({
              icon: "error",
              title: "Something went wrong",
              text: "Sorry, we failed to receive your contact request. Please try again, or dial 833-896-9335.",
            });
          });
      } catch (error) { //TODO: This is not going to catch a failed API call if the associated function returns that response without an internal error! Need to either check response directly or raise error in handler when API call fails.
        console.error("Request failed:", error.message);
        swalWithDefaults.fire({
          Icon: "error",
          title: "Something went wrong!",
          text: "Sorry, we failed to receive your contact request. Please try again, or dial 833-896-9335.",
        });
      }
    } else {
      alert("Enter a valid name and phone number");
    }
  };

  const darkCss = `
    .amplify-select {
      background:#191f21;
    }
    `;

  return (
    <ModalComponent
      position={modalPosition}
      showModal={isContactVisible}
      onClose={() => setIsContactVisible(false)}
      children={
        <Flex
          direction={"column"}
          padding={"10px"}
          overflow={"auto"}
          height={{
            base: "500px",
            small: "600px",
            medium: "auto",
          }}
          alignItems={"center"}
        >
          <style>{colorMode === "dark" && darkCss}</style>

          <Flex
            width={"fit-content"}
            justifyContent={"center"}
            alignItems={"center"}
            direction={"column"}
            border={"1px solid #c5c5c5"}
            borderRadius={"20px"}
            padding={"15px"}
            minWidth={"230px"}
          >
            <Heading>Request a Call</Heading>

            <Flex
              width={"fit-content"}
              alignItems={"center"}
              direction={{
                base: "column",
                small: "column",
                medium: "row",
                large: "row",
              }}
            >
              <View
                padding={"10px"}
                minWidth={"150px"}
                textAlign={"center"}
                direction={"column"}
              >
                <Icon fontSize={"950%"} borderRadius={"20px"} padding={"3px"}>
                  <svg
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 122.88 107.38"
                    fill="#c5c5c5"
                  >
                    <path
                      d="M81.75,54.65l-.53,1.64-.08.14-.07.09a7.23,7.23,0,0,1-2,.23,12.84,12.84,0,0,0,.61-5.63h0a1.53,1.53,0,0,1,.61-1.35,6.82,6.82,0,0,0,2.38-3.39L83,43.77a4.93,4.93,0,0,0-.19-1.05c0-.12-.08-.21-.11-.29l-.26,0A1.53,1.53,0,0,1,81,40.91c.05-4.92-.47-8-1.37-10.13-1.52-2.57-3.87-3.87-6.67-5.27-.49-.21-1-.44-1.5-.7-9.13,9.54-16.69-2.73-27.52,15.75h-.37c-.14.32-.29.64-.43,1l-.08.16a1.53,1.53,0,0,1-2.09.56q-.37-.21-.42-.18c-.06,0-.13.16-.22.37A4.7,4.7,0,0,0,40,44c-.11,2.16.59,5,2,6.37a1.51,1.51,0,0,1,.47,1.06c.16,6.6,3.09,9.12,6.65,12.19l1.5,1.3c3.57,3.18,7.34,4.82,11,4.82S68.81,68.2,72.13,65h2.42l.26,0c.49,0,1.11,0,1.81,0l-.81.77-1.48,1.4c-4,3.81-8.26,5.65-12.64,5.65s-8.92-1.91-13.07-5.6l-1.46-1.28c-3.51-3-6.49-5.59-7.42-11.21l-4.82.39a3.58,3.58,0,0,1-4.05-2.93L29,37.41a3.55,3.55,0,0,1,3.21-3.91l1.57-.13a1.78,1.78,0,0,1-.16-.65c-.94-15.35,5.68-25,14.63-29.63A29.77,29.77,0,0,1,77.36,4.6c8.18,5.27,13.52,14.91,11.12,28.32a2.45,2.45,0,0,1-.18.54l2.39.27A3.81,3.81,0,0,1,94.08,38L92.23,52.06a3.93,3.93,0,0,1-4.39,3.29h0a28.24,28.24,0,0,1-1,3.13,7,7,0,0,1-1.5,2.33c-2,2.06-8.46,2.06-10.75,2.06h-6a7,7,0,0,1-5.25,2c-3.32,0-6-1.76-6-3.93s2.68-3.93,6-3.93A7.06,7.06,0,0,1,68.4,59h6.15c1.8,0,7.14,0,8.06-.93a3,3,0,0,0,.64-1l.68-2.12-2.18-.25ZM38,27.17c3.4-15.86,21.89-25.75,39.44-15.68a18.7,18.7,0,0,1,4.27,3.36,21.12,21.12,0,0,0-6.82-7A24.84,24.84,0,0,0,62.82,4,24.39,24.39,0,0,0,50.4,6.53C43.82,9.89,38.72,16.65,38,27.17ZM44.78,73,54,97.19,58.64,84l-2.27-2.48c-1.71-2.5-1.12-5.33,2-5.84a22.86,22.86,0,0,1,3.43-.07,18.09,18.09,0,0,1,3.77.15c2.94.64,3.25,3.49,1.78,5.76L65.12,84l4.63,13.2L78.1,73c6,5.42,27.21,6.51,33.84,10.2,9.18,5.14,8.93,15,10.94,24.2H0c2-9.11,1.79-19.14,10.94-24.2C19.09,78.65,38.11,79,44.78,73Z"
                      // stroke="#c5c5c5"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Icon>
                <SelectField
                  textAlign={"left"}
                  height={"fit-content"}
                  labelHidden
                  value={requestDepartment}
                  onChange={(e) => setRequestDepartment(e.target.value)}
                >
                  <option value="General">General</option>
                  <option value="Engineering">Engineering</option>
                  <option value="Sales">Sales</option>
                </SelectField>
              </View>
              <Flex direction={"column"}>
                <TextField
                  placeholder="John Doe"
                  labelHidden
                  name="userName"
                  value={applicantName}
                  onChange={(e) => setApplicantName(e.target.value)}
                />
                <TextField
                  placeholder="833-89X-XXXX"
                  labelHidden
                  name="phoneNumber"
                  value={applicantPhoneNumber}
                  onChange={(e) => changeInPhoneNumber(e)}
                />{" "}
                <TextAreaField
                  labelHidden
                  resize="vertical"
                  placeholder="Tell us more about your project!"
                  rows={3}
                  className="textArea-styles"
                  value={requestInfo}
                  onChange={(e) => setRequestInfo(e.target.value)}
                />
              </Flex>
            </Flex>
            <Button
              width="100%"
              border={"1px solid #c5c5c5"}
              backgroundColor={"#40AABF"}
              color={"#fff"}
              onClick={() => {
                handleButtonClick();
              }}
            >
              Request Call
            </Button>
          </Flex>
          <Flex
            direction={{
              base: "column",
              small: "column",
              medium: "row",
              large: "row",
            }}
          >
            <Flex
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              direction={"column"}
              gap={{
                base: "5px",
                small: "15px",
                medium: "20px",
              }}
              textAlign={"center"}
              border={"1px solid #c5c5c5"}
              borderRadius={"20px"}
              padding={"10px"}
              minWidth={"230px"}
            >
              <View>
                <Icon
                  fontSize={"84px"}
                  borderRadius={"20px"}
                  // className="iconHover"
                  padding={"3px"}
                >
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 7.3252C3 6.79476 3.21071 6.28605 3.58579 5.91098C3.96086 5.53591 4.46957 5.3252 5 5.3252H19C19.5304 5.3252 20.0391 5.53591 20.4142 5.91098C20.7893 6.28605 21 6.79476 21 7.3252M3 7.3252V17.3252C3 17.8556 3.21071 18.3643 3.58579 18.7394C3.96086 19.1145 4.46957 19.3252 5 19.3252H19C19.5304 19.3252 20.0391 19.1145 20.4142 18.7394C20.7893 18.3643 21 17.8556 21 17.3252V7.3252M3 7.3252L12 13.3252L21 7.3252"
                      stroke="#c5c5c5"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Icon>
                <Heading marginTop={"10px"}>Send an Email</Heading>
              </View>
              <Text>
                Email the PermitZIP team for more information;{" "}
                <b>check your inbox first</b> for a sample proposal based on
                this estimate!
              </Text>
              <Button
                variation="link"
                fontSize={"15px"}
                onClick={() => {
                  window.location.href = mailtoLink;
                }}
              >
                team@permitzip.com
              </Button>
            </Flex>
            <Flex
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              direction={"column"}
              textAlign={"center"}
              gap={{
                base: "5px",
                small: "15px",
                medium: "15px",
              }}
              border={"1px solid #c5c5c5"}
              borderRadius={"20px"}
              padding={"10px"}
              minWidth={"230px"}
            >
              <View>
                <Icon
                  fontSize={"84px"}
                  borderRadius={"20px"}
                  // className="iconHover"
                  padding={"3px"}
                >
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 4.3252H9L11 9.3252L8.5 10.8252C9.57096 12.9967 11.3285 14.7542 13.5 15.8252L15 13.3252L20 15.3252V19.3252C20 19.8556 19.7893 20.3643 19.4142 20.7394C19.0391 21.1145 18.5304 21.3252 18 21.3252C14.0993 21.0881 10.4202 19.4317 7.65683 16.6684C4.8935 13.905 3.23705 10.2259 3 6.3252C3 5.79476 3.21071 5.28605 3.58579 4.91098C3.96086 4.53591 4.46957 4.3252 5 4.3252Z"
                      stroke="#c5c5c5"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Icon>
                <Heading marginTop={"10px"}>Talk to Sales</Heading>
              </View>
              <Text>
                Have more questions? Pick up the phone and chat with a member of
                our Sales and Consulting team!
              </Text>
              <Button variation="link" fontSize={"15px"}>
                <a
                  href="tel:8338969335"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  +1 (833) 896 9335
                </a>
              </Button>
            </Flex>
          </Flex>
        </Flex>
      }
    />
  );
};

export default ContactInfoModal;
